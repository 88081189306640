import React from "react"
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Container,
  Box,
} from "@mui/material"

import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { navigate } from "gatsby"
import Seo from "../components/seo"

const { Config } = require("../../config.js")

const paperStyle = {
  marginTop: "64px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}

const avatarStyle = {
  margin: "8px",
}

const formStyle = {
  width: "100%", // Fix IE 11 issue.
  marginTop: "8px",
}

const submitStyle = {
  marginTop: "24px",
  marginBottom: "16px",
}

export default class EmailVerification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      otp: "",
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(event) {
    const stringify = require("json-stringify-safe")
    var data = stringify(this.state)
    console.log(data)
    fetch(Config.route + "/api/email-verify", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: data,
    })
      .then(res => {
        if (res.ok) {
          navigate("/")
        }
      })
      .catch(err => console.log("Request Failed", err))
  }

  handleChange(event) {
    switch (event.target.name) {
      case "email":
        this.setState({ email: event.target.value })
        break
      case "otp":
        this.setState({ otp: event.target.value })
    }
  }

  render() {
    return (
      <>
        <Seo title="Email Verification" />
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box sx={paperStyle}>
            <Avatar sx={avatarStyle}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Email Verification
            </Typography>
            <form sx={formStyle}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="otp"
                label="OTP"
                type="password"
                id="otp"
                autoComplete="otp"
                onChange={this.handleChange}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={submitStyle}
                onClick={this.handleSubmit}
              >
                Verify
              </Button>
            </form>
          </Box>
        </Container>
      </>
    )
  }
}
